import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { IUser } from '../../interfaces/user/user.model';
import { useApi } from '../api/useApi';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';

export const useUser = (withFields?: boolean) => {
    const { postGql } = useApi();

    const { id: userId } = useRecoilValue(userAtom);

    return useQuery(
        ['user', withFields],
        async () => {
            const { user } = await postGql<{ user: IUser }>(
                gql`
                    query ($id: Int!, $withFields: Boolean!) {
                        user(id: $id) {
                            id
                            name
                            email
                            companyID
                            roles {
                                admin
                                access
                                name
                            }
                            fields @include(if: $withFields) {
                                firstName
                                lastName
                                kundenkennung
                                phone
                                PLZFilter
                                PLZFilterAT
                                absenceFrom
                                absenceTill
                                postalCode
                                street
                                country
                                city
                                houseNumber
                                longitude
                                latitude
                            }
                        }
                    }
                `,
                { id: Number(userId), withFields: withFields ?? false },
                `user${userId}`
            );

            // Make sure fields.trustworthy is filtered out (REMOVE it entirely
            const filteredUser = { ...user, fields: { ...user.fields } };
            delete filteredUser.fields.trustworthy;
            return filteredUser as IUser;
        },
        {
            enabled: !!userId,
        }
    );
};
